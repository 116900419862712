.swiper-button-prev, .swiper-button-next {
	color:#ffffff;
}

html:not(.sticky-header-active) #header.header-transparent:not(.header-semi-transparent) .header-body {
    background-color: rgba(0, 0, 0, .1) !important;
}

 .partnership-con img {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    /* filter: grayscale(100%); */
    filter: gray;
}

.partnership-con img:hover {
 filter: none !important;
}

.btn-edit-mode {
	transition: opacity 0.3s;
    border-radius: 4px 4px 0 0;
    top: 33px;
    color: #FFF;
    display: block;
    height: 9px;
    padding: 10px 10px 35px;
    position: fixed;
    right: 10px;
    text-align: center;
    text-decoration: none;
    min-width: 50px;
    z-index: 1040;
    font-size: 0.8em;
}

.social-icons-share { position: fixed; right: 0px; bottom: 20px; }
.btn-social { cursor: pointer; width: 155px; height: auto; border-radius: 2%;}
.social-icons-share .navbar-nav li { position: absolute; bottom: 0; z-index: -1; transition: .2s all; }

.social-icons-share .navbar-nav li:nth-child(1){bottom: 35px; }
.social-icons-share .navbar-nav li:nth-child(2){bottom: 70px; }
.social-icons-share .navbar-nav.show li:nth-child(3){bottom: 105px; }
.social-icons-share .navbar-nav.show li:nth-child(4){bottom: 140px; }
.social-icons-share .navbar-nav.show li:nth-child(5){bottom: 175px; }
.social-icons-share .navbar-nav.show li:nth-child(6){bottom: 205px; }
.social-icons-share .navbar-nav.show li:nth-child(7){bottom: 240px; }
.social-icons-share .navbar-nav.show li:nth-child(8){bottom: 275px; }

.social-icons-share .navbar-nav li:nth-child(1){bottom: 35px; right:-113px}
.social-icons-share .navbar-nav li:nth-child(2){bottom: 70px; right:-113px}
/*.social-icons-share .navbar-nav li:nth-child(3){bottom: 105px; right:-113px}
.social-icons-share .navbar-nav li:nth-child(4){bottom: 140px; right:-113px}
.social-icons-share .navbar-nav li:nth-child(5){bottom: 175px; right:-113px}
.social-icons-share .navbar-nav li:nth-child(6){bottom: 205px; right:-113px}
.social-icons-share .navbar-nav li:nth-child(7){bottom: 240px; right:-113px}
.social-icons-share .navbar-nav li:nth-child(8){bottom: 275px; right:-113px}*/

/*
.social-icons-share .navbar-nav li:nth-child(1):hover {bottom: 35px; right:0px}
.social-icons-share .navbar-nav li:nth-child(2):hover {bottom: 70px; right:0px}
.social-icons-share .navbar-nav li:nth-child(3):hover {bottom: 105px; right:0px}
.social-icons-share .navbar-nav li:nth-child(4):hover {bottom: 140px; right:0px}
.social-icons-share .navbar-nav li:nth-child(5):hover {bottom: 175px; right:0px}
.social-icons-share .navbar-nav li:nth-child(6):hover {bottom: 205px; right:0px}
.social-icons-share .navbar-nav li:nth-child(7):hover {bottom: 240px; right:0px}
.social-icons-share .navbar-nav li:nth-child(8):hover {bottom: 275px; right:0px}*/

.fb { background-color: #4561A8 !important; }
.tw { background-color: #17ADEA !important; }
.gp { background-color: #BF3B28 !important; }
.in { background-color: #1679B1 !important; }
.pi { background-color: #D9303C !important; }
.su { background-color: #E84930 !important; }
.li { background-color: #06C755 !important; }
.inst {
	background: #f09433 !important; 
background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%) !important; 
background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%) !important;  
background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%) !important; 
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}
.yt {
	background: #FF0000 !important;
}
.tt {
	background:#4e1818 !important;
}

#btn-share:hover i {
	background-color: rgba(0,0,0,0.1);
    transform: scale(1.2);
    transition: all 0.2s;
}
@media only screen and (max-width: 600px) {
	html #header.header-transparent {
		position:relative !important;
		background-color: rgba(23, 45, 103, 1) !important;
	}
	.swip-img img {
		object-fit: cover;
		width: 100%;
		height: auto !important;
		/* height: calc(100vh - 82px); */
		display: none;
	}
	.swip-banner-item {
		top:10% !important;
	}
	
	.text-11 {
		font-size: 3vh !important;
	}
	
	.swiper-slide p {
		font-size: 1.5vh !important;
	}
	
	.swiper-slide .fas {
		font-size: 1.5vh !important;
	} 

	.swiper-slide .btn {
		font-size:  1.5vh !important;
	}
  }

#header .header-btn-collapse-nav {
	background-color: #172D67 !important;
	border: 1px solid #ffffff !important;
}
.white-space-normal {
	white-space: normal !important;
}
body {
	font-family:"Prompt", Arial, sans-serif !important;
}
h1,h2,h3,h4,h5,h6 {
	letter-spacing:0em !important;
}

.h1,.h2,.h3,.h4,.h5,.h6 {
	letter-spacing:0em !important;
}
#header .header-nav.header-nav-dropdowns-dark nav > ul > li.dropdown li a {
	border-bottom: none !important;
}
@media (max-width: 991px) {
#header .header-nav-main.header-nav-main-mobile-dark nav > ul li{
	border-bottom: none !important;
}
#header .header-nav-main.header-nav-main-mobile-dark nav > ul > li > a
{
	color:#474747 !important;
	font-weight: bold;
}
}
video {
	position: absolute;
	left: 50%; /* % of surrounding element */
    top: 50%;
    transform: translate(-50%, -50%);
	
	min-height: 100%;
	z-index:0;
  }
  .btn-light:hover, html body .btn-light.hover, html body .btn-outline.btn-light:hover, html body .btn-outline.btn-light.hover
  {
	color:#777 !important;
  }
.fade-me-in.htmx-added {
	opacity: 0;
  }
  .fade-me-in {
	opacity: 1;
	transition: opacity 1s ease-out;
  }
.swip-item{padding: 40vh 20px 0;}
.swip-list-pagination{position: absolute;left: 50%;transform: translateX(-50%); bottom: 15px;height: 8px; text-align: center;z-index: 3;}
.swip-list-pagination .swiper-pagination-bullet{background: #eee;opacity: 1;margin: 0 4px;}
.swip-list-pagination .swiper-pagination-bullet-active{background: #266ef2;}
.swip{background: #000;}
/*.swip-img .swiper-slide::after{content: "";position: absolute;left: 0;top: 0;width: 100%;height: 100%;background: rgba(0, 0, 0, .3);}*/
.slide-black-bg::after{content: "";position: absolute;left: 0;top: 0;width: 100%;height: 100%;background: rgba(0, 0, 0, .3);}


.swip-img img{ width: 100%;display: none;}
.swip-img img:first-child{display: block;}
.swip-list{position: absolute;left: 0;top: 0;width: 100%;height: 100%;z-index: 2;}
.swip-list .swiper-container,.swip-list .swiper-wrapper{height: 100%;}
.swip-item{padding: 40vh 20px 0;border-left: 1px solid rgba(255, 255, 255, .2);user-select: none;cursor: pointer;height: 100%;}
.swip-banner-item{top: 40%;
    position: absolute;border-left: none !important;user-select: none;cursor: pointer;height: 100%;}
.swiper-slide:first-child .swip-item{border: unset;}
.swip-text{display: none;}
.swip-list .swiper-container, .swip-list .swiper-wrapper {
    height: 100%;
}


.banner-list-pagination{position: absolute;left: 50%;transform: translateX(-50%); bottom: 15px;height: 8px; text-align: center;z-index: 3;}
.banner-list-pagination .swiper-pagination-bullet{background: #eee;opacity: 1;margin: 0 4px;}
.banner-list-pagination .swiper-pagination-bullet-active{background: #266ef2;}

.banner-list{position: absolute;left: 0;top: 0;width: 100%;height: 100%;z-index: 2;}
.banner-list .swiper-container,.banner-list .swiper-wrapper{height: 100%;}
.banner-list .swiper-container, .banner-list .swiper-wrapper {
    height: 100%;
}

html body .tabs.tabs-simple .nav-tabs > li .nav-link.active, html.dark body .tabs.tabs-simple .nav-tabs > li .nav-link.active, html body .tabs.tabs-simple .nav-tabs > li .nav-link.active:focus, html.dark body .tabs.tabs-simple .nav-tabs > li .nav-link.active:focus, html body .tabs.tabs-simple .nav-tabs > li .nav-link:hover, html.dark body .tabs.tabs-simple .nav-tabs > li .nav-link:hover, html body .tabs.tabs-simple .nav-tabs > li .nav-link.active:hover, html.dark body .tabs.tabs-simple .nav-tabs > li .nav-link.active:hover
{
	background: linear-gradient(0deg, rgb(46, 115, 234) 0%, rgb(83, 193, 255) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
html body .tabs.tabs-bottom .nav-tabs li .nav-link:hover, html.dark body .tabs.tabs-bottom .nav-tabs li .nav-link:hover, html body .tabs.tabs-bottom .nav-tabs .nav-link.active, html.dark body .tabs.tabs-bottom .nav-tabs .nav-link.active, html body .tabs.tabs-bottom .nav-tabs .nav-link.active:hover, html.dark body .tabs.tabs-bottom .nav-tabs .nav-link.active:hover, html body .tabs.tabs-bottom .nav-tabs .nav-link.active:focus, html.dark body .tabs.tabs-bottom .nav-tabs .nav-link.active:focus
{
	border-image-slice:1;
	border-image-source: linear-gradient(to right, rgb(46, 115, 234), rgb(83, 193, 255));
}
.thumb-info {
	background-color: unset !important;
}



 .thumb-info-title {
	border-radius: 0px 20px 20px 0px;
    left: 75% !important;
    height: 100%;
   
    top: auto;
    bottom: 0 !important;
    text-shadow: none;
  
}

.border-grey {
	border: 2px solid rgba(140, 140, 140, 0.3) !important;
	
}

.thumb-gallery-wrapper .thumb-gallery-thumbs .owl-item:hover, .thumb-gallery-wrapper .thumb-gallery-thumbs .owl-item.selected {
    border: 2px solid rgba(140, 140, 140, 0.3) !important;
	border-radius: 4px !important;
}
.crop {
	position: relative;
                    width: 100%;
                    height: 0;
                    padding-top: calc(100% * ( 225 / 300));
}

.img-crop {
	position: absolute;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
}
.fa-circle-custom {
	background-color: rgba(46, 115, 234, 1);
    /* height: 16px; */
    padding: 7px;
    border-radius: 50%;
    color: white;
    margin-right: 10px;
}
.img-border-radius {
	border-radius: 20px;
}
.thumb-info img
{
	border-radius: 20px;
}
.thumb-info p {
 font-size:16px;
 line-height: 24px;
 font-weight: normal;
}
.thumb-info .thumb-info-title
{
    background: rgba(46, 115, 234, 1) !important;
	opacity: 0.85;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
}
.thumb-info-wrapper img {
    border-radius: 20px !important;
}

.bg-dark {
	background-color: #2e3233;
}
.blog-posts:not(.blog-posts-no-margins) article {
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.06); */
	border-bottom: none;
    margin-bottom: 50px;
    padding-bottom: 20px;
}

.toggle.active a.toggle-title p
{
	color:white !important;
}
.text-1 p {
	font-size: .8em !important;
}

.pad-2 p {
padding-right: 0.5rem!important;
padding-left: 0.5rem!important;}
.btn-blue-gradient {
	background: linear-gradient(90deg, rgb(46, 115, 234) 0%, rgb(83, 193, 255) 100%);
}
.text-blue-gradient {
	background: linear-gradient(0deg, rgb(46, 115, 234) 0%, rgb(83, 193, 255) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.text-blue-gradient::selection {
	color: white;
}

.bg-header {
	background-color: rgba(23, 45, 103, 1) !important;
}

.plan-price .price {
    display: flex;
    
    align-items: flex-start;
 
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1;
    color: #212529;
}
.company-profile span.h2 {
	color:#777;
}
.location p {
	margin:0px;
}
.company-profile h2 {
	color:#777;
	font-size: 2.2em;
    font-weight: 300;
    line-height: 42px;
    margin: 12px 0 12px;
}
.text-white p {
	color:white;
	margin-bottom:0px;
}
.bg-blue {
	background-color: #0164a7 !important;
}

.bg-grey {
	background-color: #f7f7f7;
}
.owl-dots {
   
    text-align: center;
    height: 30px;
   
}

.border-radius {
	border-radius: 15px !important;
}

.img-thumbnail {
	border-radius: 15px !important;
}
.owl-theme .owl-nav.disabled+.owl-dots {
	margin-top:0px;
}
.crop {
	position: relative;
                    width: 100%;
                    height: 0;
                    padding-top: calc(100% * ( 225 / 300));
}

.img-crop {
	position: absolute;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
}

.heading.heading-bottom-border h4, .heading.heading-bottom-border h5, .heading.heading-bottom-border h6 {
	border-bottom: 1px solid black;
}
.truncate-2-line {
	max-width: 300px;
	-webkit-line-clamp: 2;
	/* autoprefixer: off */
	-webkit-box-orient: vertical;
	/* autoprefixer: on */
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	height:47px;
}

.page-heading.center span {
    margin: 8px auto 0 auto;
}
.page-heading h2 > span, .page-heading h3 > span {
    display: block;
    margin-top: 8px;
    width: 50px;
    height: 2px;
    background: #4c4c4c;
}

.page-heading h2.text-white > span, .page-heading h3.text-white > span {
    display: block;
    margin-top: 8px;
    width:100%;
    height: 2px;
    background:#ffffff;
}

.bg-dark p {
	color:white !important;
}

.bg-color-hover-primary:hover .white-text-hover, .bg-color-hover-primary:hover .white-text-hover p{
    /* background-color: #0088cc !important; */
	color:#fff !important;
}
.bg-color-hover-primary:hover
{
	background: linear-gradient(180deg, #4394ED 0%, #2C6CB2 100%) !important;
}
#header .header-nav-main nav > ul > li.open > a, #header .header-nav-main nav > ul > li:hover > a {
	background:#27364b !important;
	color:white !important;
}

#header .header-nav-main nav > ul > li.dropdown.open > a, #header .header-nav-main nav > ul > li.dropdown.accessibility-open > a, #header .header-nav-main nav > ul > li.dropdown:hover > a
{
	border-radius: 0;
}

#header .header-nav-main nav > ul > li.dropdown.open > a:before, #header .header-nav-main nav > ul > li.dropdown:hover > a:before
{
	border:0;
}
.our-vision p , .our-vision  h2{

	color:#4D2D2D !important;
	font-weight:normal;
}
.product-desc {
	color:black !important
}
#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li a {
	color:#474747 !important;
	opacity: 0.8;
	font-weight: 500;
	
}
#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li a:hover {
	color:#ffffff !important;
	background: linear-gradient(0deg, rgb(46, 115, 234) 0%, rgb(83, 193, 255) 100%) !important;
}


#header .header-body {
	/*opacity: 0.8;*/
	background-color: #ffffff;
	border-bottom:0px;
}

.border-bg-dark {
	border: 1px solid #404549;
}

.more {
 
    width: 100%;
  
    font-size: 1.3em;
    border-top: 1px solid #404549;
    border-bottom: 1px solid #404549;
    
}

.section-text-light .testimonial.testimonial blockquote:before, .section-text-light .testimonial.testimonial blockquote:after, .section-text-light .testimonial.testimonial blockquote p
{
	color:black;
}
.owl-employee.owl-theme .owl-dots .owl-dot span {
	background:#ffffff !important;
}
.owl-employee.owl-theme .owl-dots .owl-dot.active span, .owl-employee.owl-theme .owl-dots .owl-dot:hover span
{
	background:blue !important;
}
.testimonial blockquote {
    background: #CCC;
    border-radius: 5px;
    border: 0;
    color: #666;
	font-family: unset !important;
    margin: 0;
    padding: 40px 64px 40px 72px;
    padding: 2.5rem 4rem 2.5rem 4.5rem;
    position: relative;
}

.testimonial blockquote p {
    color: #666;
     font-family: unset !important;
    font-size: 1.2em;
}

/* .benefit-box p {
	font-family: 'Poppins';
	
} */

.bg-theme {
	background-color: #0164a7 !important;
	border-color: #0164a7 !important;
}

.testimonial blockquote:before {
    font-family: 'Playfair Display', serif;
    left: 22px;
    top: 21px;
    color: #FFF;
    content: "“";
    font-size: 85px;
    font-style: normal;
    line-height: 1;
    position: absolute;
}

#footer a:not(.btn):not(.no-footer-css):hover {
 color:#2C6CB2 !important;
}

.bg-color-hover-primary:hover  img{

	-webkit-filter: brightness(0) invert(1);
	filter: brightness(0) invert(1);
  }

.btn-danger {
	background-color: #DE5E99 !important;
	border-color:#DE5E99 !important
}

.btn-outline.btn-danger {
	background-color: transparent !important;
	color:#DE5E99 !important;
	
}

.btn-outline.btn-danger.active {
	background-color: #DE5E99 !important;
	border-color:#DE5E99 !important;
	color:white !important;
	
}

.owl-carousel .owl-dots .owl-dot.active span, .owl-carousel .owl-dots .owl-dot:hover span {
	background-color: #869791 !important;
}

.btn-danger.btn-outline
{
	border-radius: 0px;
}
.employee .featured-box {
	border: none;
	background:#0088cc !important;
}
.featured-box .box-content {
 border-radius: 0px;
}
.owl-employee.owl-carousel.owl-carousel .owl-dots .owl-dot.active span, .owl-carousel .owl-dots .owl-dot:hover span {
    background-color: #fff;
}
.owl-employee.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #fff;
}

.timeline-container {
	margin-top:30px;
  
}
.timeline-container {
	margin-top:0px;
	border-top: 3px #B7852C solid;
}

.desktop-carousel .owl-prev span {
	display:none;
  }
  .desktop-carousel .owl-next span {
	display:none;
  }

/*timeline slider styles*/
.timeline-slide {
	
	margin: 10rem 0 0 0;
	width: 100%;
	
	position:relative;
}
.timeline-slide .circle {
	border: 1px #DE5E99 solid;
	background-color: white;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-redius: 50%;
	height: 20px;
	width: 20px;
	position: absolute;
	left: 50%;
	margin-left: -15px;
	top: -28.5px;
	z-index: 2000;
	overflow: visible;
}
.timeline-copy {
  
  
  
	margin: 1rem;
	padding-bottom: 5px;
}
.timeline-slide .vertical-line {
	border-left: 3px #B7852C solid;
	height: 50px;
	position: absolute;
	left: 50%;
	margin-left: -4.5px;
	top: -50px;
	z-index: 1000;
}
.timeline-slide .timeline-copy h3 {
	font-size: 2.5rem;
	font-weight: 800;
	margin-bottom:0px;
}
.timeline-slide .timeline-copy {
	padding: 1rem 0.5rem;
	color: #fff;
}
.timeline-container {
  margin-top:20px;
  padding-top:6px;
}

.bg-pink {
	background-color: #DE5E99 !important;
}

.timeline-slide .circle2 {
	border: 1px #fff solid;
	background-color: #DE5E99;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-redius: 50%;
	height: 10px;
	width: 10px;
	position: absolute;
	left: 50%;
	margin-left: -2px;
	top: -43px;
	top: -70px;
	/* top: 21px; */
	z-index: 2000;
	overflow: visible;
}

.line:after {
	left: 50%;
	content: "";
	position: absolute;
	height: 1px;
	width: 100%;
	top: -19px;
	background: #DE5E99;
/* top: 45%; */


}

.line:last-child{
	display:none;
}

.year-block {
	position: absolute;
	top: -70px;
   

}
button:focus {outline:0;}

@media screen and (max-width: 560px) {
  .timeline-slide {
	margin-top: 4.2rem;
	
  }

}
@media (max-width: 991px){
	.thumb-info-title {
		left: 0 !important;
	}

	.thumb-info .thumb-info-title {
		max-width: 100%;
		border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
	width:100%;
	}

#header .header-nav-main nav > ul li a:active {
    background-color: transparent;
    color: inherit;
}
}
@media (min-width: 992px)
{
#header .header-nav.header-nav-links nav > ul > li.dropdown.open > .dropdown-menu, #header .header-nav.header-nav-links nav > ul > li.dropdown.accessibility-open > .dropdown-menu, #header .header-nav.header-nav-links nav > ul > li.dropdown:hover > .dropdown-menu, #header .header-nav.header-nav-line nav > ul > li.dropdown.open > .dropdown-menu, #header .header-nav.header-nav-line nav > ul > li.dropdown.accessibility-open > .dropdown-menu, #header .header-nav.header-nav-line nav > ul > li.dropdown:hover > .dropdown-menu {
	/*background-color: rgba(44,108,178,.6);*/
	background-color:white;
}

}

@media (max-width: 991px) {
	#header .header-nav-main nav {
		/*background: rgba(44,108,178,.6) !important;*/
		background:#ffffff !important;
		height:41vh !important;
	}
	#header .header-nav-main:not(.header-nav-main-mobile-dark) nav > ul > li > a {
		color: #555555 !important;
	}
	

	.footer-center 
	{
		text-align: center!important;
	}
}
.accordion.accordion-modern-status.accordion-modern-status-borders > .card .card-header a
{
	background-color:white;
}
#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li:hover > a {
	background-color:transparent;
}
 .footerAddress li p {
	color:#ffffff !important;
	margin-bottom:1rem;
}

.footerAddress li a {
	color:#ffffff !important;
	margin-bottom:1rem;
}

.our-people .our-people-header p {
	color:white;
	opacity: 1;
}

ul.comments .comment-arrow {
	border-top: 12px solid #ffffff;
	border-bottom: 0px solid #fcfcfc;
	border-left: 12px solid #2e71b0;
	border-right: 12px solid #2e71b0;
height: 0;
left: 42px;
top:100%;
position: absolute;
/* top: 12px; */
width: 0;
}
.our-people ul.comments .comment-block {
background: #ffffff;

}

.our-people ul.comments li .img-thumbnail {
margin-left:unset;
}

.our-people  ul.comments li {
clear: both;
padding: 0px 0 0 0px;
border-left: 0px;
}
.our-people  .section-text-light .testimonial.testimonial blockquote:before, .section-text-light .testimonial.testimonial blockquote:after, .section-text-light .testimonial.testimonial blockquote p
{
color:black;
}
.our-people  .testimonial-author div span
{   opacity: 1 !important;
color:white !important;
}
.our-people .testimonial-primary .testimonial-arrow-down {
border-top-color: #ffffff;
}
.owl-carousel.stage-margin .owl-stage-outer {
	margin-left: 0px !important;
	margin-right:0px !important;
}
.bg-management {
    background-color: #e6e5e5;
	padding: 0.3rem !important;
    font-size: 0.8em;
    text-transform: uppercase;
 }
.transportation ul li {
	color:#777;
}
 .location p {
	letter-spacing: 0em;
 }
 .nathalin-color {
        color:#DE5E99;
 }
 .prima-color {
        color:#35ACE5;
 }
.bg-nathalin {
    background-color:#DE5E99 !important;
}
.bg-nathalin:hover,.nathalin-color:hover {
    opacity: 0.7;
	
}
 
.bg-prima {
        background-color:#35ACE5 !important;
}
 .border-nathalin {
    border-left: 5px solid #DE5E99 !important;
   
 }
 
 .border-prima {
    border-left: 5px solid #35ACE5 !important;
 }


.employee.featured-boxes-modern-style-2 .featured-box .box-content p {
    color:unset;
    margin-bottom: 25.6px;
    margin-bottom: 1.6rem;
    font-size: 1.5em;
}
aside ul.nav-list > li > a{
	color:black !important;
}
.text-join-box p {
    margin-bottom:0px !important;
}
.owl-employee.owl-carousel .owl-item img{
display: unset;
width: auto;
}

.btn-blue {
	background-color: rgb(46, 115, 234);
}
.btn-blue:hover {
	background-color: rgb(46, 115, 234);
}
	.text-white p  {
		color: white !important;
		}

#mainNav li.white-menu a {
	color:rgba(71, 71, 71, 1) !important;
}
.header-nav-features a {
	color:black !important;
}
.text-pink,.text-pink p {
	color:#DE5E99;
}

.text-marine,.text-marine p {
	color:#2C2750 !important;
}
.social-icons.social-icons-clean-with-border li a {
	color:#2C2750 !important;
}
ul.solution li a:before {
	content: "\f111" !important;
	margin-top: 4px;
    font-size: 4px;
}

.border-radius-20 {
	border-radius: 20px !important;
}
.reference-info .border-radius {
	border-radius: 20px !important;
}
.reference-info .border-radius.box-shadow-1:before {
	border-radius: 20px !important;
}
.bg-color-blue {
	color:white !important;
	background-color:rgba(46, 115, 234, 1) !important;
}
.text-blue,.text-blue p {
	color:rgba(46, 115, 234, 1) !important;
}
#footer.footer-texts-more-lighten p, #footer.footer-texts-more-lighten span, #footer.footer-texts-more-lighten a:not(.btn):not(.no-footer-css), #footer.footer-texts-more-lighten li
{
	color:black !important;
}
#footer {
background-color: rgba(23, 45, 103, 1) !important;
}
#footer p {
	color: white !important;
}
#footer .footer-copyright.footer-copyright-style-2 {
	background-color: rgba(23, 45, 103, 1)  !important;
}
.card-body .text-in-accordion {
	color:#555555;
}

.border-item {
	border: 1px solid rgba(0, 0, 0, 0.06) !important;
}

.accordion.accordion-modern-status.accordion-modern-status-borders > .card.open-position {
    border: 1px solid rgba(0, 0, 0, 0.085) !important;
}
.accordion.accordion-modern-status.accordion-modern-status-borders > .card {
	  border: 0px !important;
}

.accordion.accordion-modern-status > .card .card-header a {
	padding:0px !important; 
}
.accordion.accordion-modern-status > .card + .card {
	margin-top:0px !important;
}
.accordion .card .card-header .card-title {
	padding: 1rem 0rem;
}
#revolutionSlider p{
	color:#ffffff;
}
section.section.section-background.wave {
    min-height: 500px;
    /* border-radius: 50%; */
    border-bottom-left-radius: 40%;
    border-bottom-right-radius: 40%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

section.section.section-background.wave:before,.space.shadow-bg:before{
	content:'';
	height:100%;
	width:100%;
	position: absolute;
	top:0;
	left:0;
	background-color: rgba(0,0,0,0.3);
}

small, .small{
    font-size:13pt;
}
@media (min-width: 992px) {
	
}

@media (min-width: 768px) {
	
}

@media (max-width: 575px) {
	
	section.section.section-background.wave,section.section.section-background.wave:before {
		
		/* border-radius: 50%; */
		border-bottom-left-radius: 10%;
		border-bottom-right-radius: 10%;
	}
	

}

.particle.particle-dots-gte {
    -webkit-mask-image: url(../img/dot.svg);
    mask-image: url(../img/dot.svg);
    z-index: 0;
    -webkit-mask-size: 18px;
    border-radius: 100%;
}