

@media (max-width: 440px){
#header .header-nav-features .header-nav-features-dropdown.header-nav-features-dropdown-mobile-fixed {
    position: fixed;
    right: auto;
     left: unset !important;
     transform: unset !important;
}
}


.owl-carousel.show-nav-hover .owl-nav {
    opacity: unset;
    transform:unset !important;
    transition:unset !important;
}

.owl-carousel.show-nav-hover:hover .owl-nav button.owl-prev{
    left:0;
}

.owl-carousel.show-nav-hover:hover .owl-nav button.owl-next{
    right:0;
}


.owl-carousel.dots-inside .owl-dots
{
    right:50%;
}